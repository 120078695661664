








import Vue from 'vue'

export default Vue.extend({
  name: 'BaseRadioButton',
  components: {},
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  data () {
    return {
      check: ''
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    opinionText: {
      type: String,
      required: false
    },
    modelValue: {
      default: ''
    },
    value: {
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldBeChecked () {
      return this.modelValue === this.value
    }
  },
  methods: {
    onClick () {
      if (this.disabled) return
      this.$emit('change', this.value)
    }
  }
})
