














































































































import Vue from 'vue'

import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'DetailedTargeting',
  components: {
    VSelect
  },
  props: ['new_audience'],
  data () {
    return {
      interests: [],
      interests_exclusion: [],
      inclusion_targeting: this.new_audience.inclusion_targeting || [],
      exclusion_targeting: this.new_audience.exclusion_targeting || {},
      excluded_interests: this.new_audience.inclusion_targeting || {},
      loading_include_interests: [false],
      loading_exclude_interests: false,
      activeExcludeBlock: !!Object.keys(this.new_audience.exclusion_targeting).some(v => this.new_audience.exclusion_targeting[v].length !== 0)
    }
  },
  computed: {
    ...mapGetters('adsManager', [
      'getCampaign'
    ])
  },
  mounted () {
    for (let i = 0; i < this.inclusion_targeting.length; i += 1) {
      this.interests.push([])
    }
  },
  methods: {
    ...mapActions('adsManager', [
      'get_interests'
    ]),
    ...mapActions('notifications', ['addNotification', 'addExceptions']),
    search_interests:
      _.throttle(async function ({ str, loading, index = null }) {
        if (str.length >= 3) {
          if (index || index === 0) {
            this.loading_include_interests.splice(index, 1, true)
          } else {
            this.loading_exclude_interests = true
          }
          this.get_interests({
            campaignId: this.getCampaign.id,
            query: str
          }).then(response => {
            if (index || index === 0) {
              this.interests[index] = response.data
              this.loading_include_interests.splice(index, 1, false)
            } else {
              this.interests_exclusion = response.data
            }
            this.loading_exclude_interests = false
            loading(false)
          })
        }
      }, 500),
    interests_options (index) {
      return this.interests[index] && this.interests[index]
        .filter(i =>
          !(this.inclusion_targeting[index][i.type]
            .find(v => v.fb_id === i.fb_id)))
        .map(i => {
          return {
            ...i,
            code: i.fb_id,
            label: i.name
          }
        })
    },
    interests_excluded_options () {
      return this.interests_exclusion
        .filter(i =>
          !(this.exclusion_targeting[i.type]
            .find(v => v.fb_id === i.fb_id))
        )
        .map(i => {
          return {
            ...i,
            code: i.fb_id,
            label: i.name
          }
        })
    },
    select_include_interest (value, index) {
      if (!this.inclusion_targeting[index][value.type]) {
        this.inclusion_targeting[index][value.type] = true
      }
      this.inclusion_targeting[index][value.type]
        .push({
          fb_id: value.fb_id,
          name: value.name,
          audience_size: value.audience_size
        })
    },
    select_exclude_interest (value) {
      this.exclusion_targeting[value.type]
        .push({
          fb_id: value.fb_id,
          name: value.name,
          audience_size: value.audience_size
        })
    },
    addIncludeItem () {
      this.interests.push([])
      this.loading_include_interests.push(false)
      this.inclusion_targeting.push({
        id: this.inclusion_targeting.length,
        interests: [],
        behaviors: [],
        life_events: [],
        income: [],
        family_statuses: []
      })
    },
    delete_include_item (item, index) {
      const types = this.inclusion_targeting[index][Object.keys(item)[0]]
      const ind = types.indexOf(types.find(v => v.fb_id === item[Object.keys(item)[0]].fb_id))
      types.splice(ind, 1)
    },
    delete_exclude_item (item) {
      const types = this.exclusion_targeting[Object.keys(item)[0]]
      const ind = types.indexOf(types.find(v => v.fb_id === item[Object.keys(item)[0]].fb_id))
      this.exclusion_targeting[Object.keys(item)[0]].splice(ind, 1)
    },
    deleteIncludeBlock (ind) {
      const index = this.inclusion_targeting
        .indexOf(this.inclusion_targeting.find(v => v.id === ind))
      this.inclusion_targeting.splice(index, 1)
      this.inclusion_targeting.map((v, index) => {
        v.id = index
      })
    },
    deleteExcludeBlock () {
      this.exclusion_targeting = {
        interests: [],
        behaviors: [],
        life_events: [],
        income: [],
        family_statuses: []
      }
      this.activeExcludeBlock = false
    },
    changeInclusionTargeting (event) {
      const init = this.inclusion_targeting
      this.inclusion_targeting = []
      this.inclusion_targeting = init
      this.inclusion_targeting[event.id] = event
    },
    textTransform (text) {
      return _.capitalize(_.lowerCase(text))
    },
    refactor_selected_included_interests_show (item) {
      const types = []
      const r = []
      if (item) {
        Object.keys(item).forEach(v => {
          types.push(v)
        })
        types.forEach(i => {
          if (i !== 'id') {
            item[i].forEach(t => {
              const it = {}
              it[i] = t
              r.push(it)
            })
          }
        })
        return r
      } else {
        return []
      }
    }
  }
})
