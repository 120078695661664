




























































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
export default Vue.extend({
  name: 'locations',
  props: {
    include: { required: true, type: Boolean },
    distanceOptions: { required: false, type: Array },
    locations: { required: true, type: Array }
  },
  components: {
    VSelect
  },
  methods: {
    changeRadius (location, event) {
      this.$emit('change-location-radius', {
        key: location.key,
        value: event
      })
    },
    removeLocation (location) {
      this.include === true
        ? this.$emit('removeIncludedLocation', location)
        : this.$emit('removeExcludedLocation', location)
    }
  }
})
