import _ from 'lodash'

export default {
  getStringNumber (num) {
    if (!num) {
      return 0
    }
    let value = ''
    for (let i = 0; i < num; i++) {
      if (num / 10 > 0) {
        value = (num % 10) + value
        if ((i + 1) % 3 === 0) value = ',' + value
      }
      num = Math.trunc(num / 10)
    }
    value = num + value
    return _.trimStart(value, '0,')
  }
}
