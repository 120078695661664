











































































































































































































































































































































































































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import Switcher from '@/components/Switcher.vue'
import BaseRadioButton from '@/components/BaseRadioButton.vue'
import Locations from '@/components/ADSManager/Locations/Locations.vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import DetailedTargeting from '@/components/ADSManager/NewCampaign/Audience/DetailedTargeting.vue'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import formatters from '@/utils/formatters'
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue'
export default Vue.extend({
  name: 'EditAudience',
  components: {
    VSelect,
    DetailedTargeting,
    BaseRadioButton,
    BaseInputVuelidate,
    Locations,
    BaseDropdown,
    Switcher
  },
  props: {
    campaign: {
      required: true
    },
    audience: {
      required: true
    },
    is_adSet_edit: {
      type: Boolean,
      default: false
    },
    delete_available: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      audience_name: null,
      include_exclude_locations_options: ['Include', 'Exclude'],
      include_exclude_interests_options: ['Include', 'Exclude'],
      locations_setting: 'Include',
      interests_setting: 'Include',
      audience_setting: 'Include',
      included_audiences: [],
      excluded_audiences: [],
      geo_locations: [],
      include_geo_locations: [],
      exclude_geo_locations: [],
      inclusion_targeting: [],
      exclusion_targeting: {},
      distance_options: [...Array(10).keys()].map(item => item * 5 + 5),
      distance_options_exclude: [...Array(6).keys()].map(item => item * 5 + 25),
      location_type: null,
      age_min: 18,
      age_max: 65,
      editable: {
        age: false,
        genders: false
      },
      genders: 0,
      interests: [],
      activeDetailTargeting: false,
      included_interests: [],
      excluded_interests: [],
      selected_interests: [],
      loading: {
        interests: false
      },
      selected_locales: [],
      potential_reach_number: 0
    }
  },
  created () {
    this.audience_name = this.audience.audience_name
    this.included_audiences = this.audience.audiences.filter(a => a.is_included)
    this.excluded_audiences = this.audience.audiences.filter(
      a => !a.is_included
    )
    this.include_geo_locations = this.audience.locations.filter(
      l => l.is_included
    )
    this.exclude_geo_locations = this.audience.locations.filter(
      l => !l.is_included
    )
    if (this.audience.location_types) {
      const type = this.getLocationTypes.find(l => l.id === this.audience.location_types)
      this.location_type = { ...type, code: type.id, label: type.name }
    }
    this.age_min = this.audience.age_min
    this.age_max = this.audience.age_max
    this.genders = this.audience.genders
    // this.selected_interests = this.audience.interests || []
    if (this.audience.inclusion_targeting && Object.keys(this.audience.inclusion_targeting).length > 0) {
      this.inclusion_targeting = this.audience.inclusion_targeting.map((v, index) => {
        return /* const obj */ {
          id: index,
          interests: v.interests || [],
          behaviors: v.behaviors || [],
          life_events: v.life_events || [],
          income: v.income || [],
          family_statuses: v.family_statuses || []
        }
      })
    } else {
      this.inclusion_targeting = []
    }
    // *****
    const tre = { interests: [], behaviors: [], life_events: [], income: [], family_statuses: [] }
    this.exclusion_targeting = { interests: [], behaviors: [], life_events: [], income: [], family_statuses: [] }
    Object.keys(tre).forEach(v => {
      if (this.audience.exclusion_targeting && this.audience.exclusion_targeting[v]) {
        this.exclusion_targeting[v] = this.audience.exclusion_targeting[v]
      } else {
        this.exclusion_targeting[v] = []
      }
    })
    this.selected_locales = this.audience.locales
      ? this.audience.locales.map(locale => {
        const option = this.getLocales.find(l => l.key === locale)
        return {
          code: option.key,
          label: option.name
        }
      })
      : []
  },
  mounted () {
    this.apply_watchers()
    if ((!!this.audience && !!this.audience.inclusion_targeting && this.audience.inclusion_targeting.length !== 0) ||
      (this.audience.exclusion_targeting && this.audience.exclusion_targeting.length !== 0) ||
      (this.included_interests_for_send[0] && Object.keys(this.included_interests_for_send[0]).some(v => this.included_interests_for_send[0][v].length !== 0))) {
      this.activeDetailTargeting = true
    }
  },
  computed: {
    ...mapGetters('adsManager', [
      'getCampaign',
      'getCustomAudiences',
      'getLocationTypes',
      'getLocales',
      'getTargeting',
      'getTargetingAudiencesData',
      'getTargetingIncludedLocations',
      'getTargetingExcludedLocations'
    ]),
    locales_options () {
      return this.getLocales.map(l => {
        return {
          label: l.name,
          code: l.key
        }
      })
    },
    campaign_name () {
      return this.getCampaign?.name
    },
    geo_locations_options () {
      return this.geo_locations
        .filter(
          l =>
            this.include_geo_locations.filter(il => il.key === l.key).length ===
              0 &&
            this.exclude_geo_locations.filter(el => el.key === l.key).length ===
              0
        )
        .map(l => {
          if (l.region) {
            return {
              ...l,
              code: l.key,
              label: `${l.name}, ${l.region}, ${l.country_name}`,
              chosen_radius: l.type === 'city' ? 25 : null
            }
          } else {
            return {
              ...l,
              code: l.key,
              label: `${l.name}, ${l.country_name}`,
              chosen_radius: l.type === 'city' ? 25 : null
            }
          }
        })
    },
    customAudiencesOptions () {
      return this.getCustomAudiences
        .filter(
          a =>
            !this.included_audiences.includes(a) &&
            !this.excluded_audiences.includes(a)
        )
        .map(a => {
          return {
            label: a.name,
            code: a.fb_id,
            type: a.subtype
          }
        })
    },
    locationTypes () {
      return this.getLocationTypes.map(l => {
        return {
          label: l.name,
          code: l.id,
          description: l.description
        }
      })
    },
    // interests_options () {
    //   return this.interests
    //     .filter(
    //       i =>
    //         this.selected_interests.filter(si => si.fb_id === i.fb_id)
    //           .length === 0
    //     )
    //     .map(i => {
    //       return {
    //         ...i,
    //         code: i.fb_id,
    //         label: i.name
    //       }
    //     })
    // },
    audiences () {
      return this.new_audiences.concat(this.getTargetingAudiencesData)
    },
    locations () {
      return this.include_geo_locations
        .map(l => {
          return {
            ...l,
            is_included: true,
            radius: l.chosen_radius
          }
        })
        .concat(
          this.exclude_geo_locations.map(l => {
            return {
              ...l,
              is_included: false,
              radius: l.chosen_radius
            }
          })
        )
    },
    new_audience_all_audiences () {
      return this.included_audiences
        .map(a => {
          return {
            ...a,
            is_included: true
          }
        })
        .concat(
          this.excluded_audiences.map(a => {
            return {
              ...a,
              is_included: false
            }
          })
        )
    },
    included_interests_for_send () {
      const arr = []
      this.inclusion_targeting.forEach(v => {
        const obj = {}
        Object.keys(v).forEach(t => {
          if (t !== 'id' && v[t].length > 0) {
            obj[t] = v[t]
          }
        })
        if (Object.keys(obj).length !== 0) {
          arr.push(obj)
        }
      })
      return arr
    },
    excluded_interests_for_send () {
      const obj = {}
      Object.keys(this.exclusion_targeting).forEach(t => {
        if (this.exclusion_targeting[t].length > 0) {
          obj[t] = this.exclusion_targeting[t]
        }
      })
      return obj
    },
    new_audience_data () {
      const obj = {}
      obj['age_max'] = this.age_max
      obj['age_min'] = this.age_min
      if (this.audience_name) {
        obj['audience_name'] = this.audience_name
      }
      obj['audience_size'] = this.potential_reach_number
      obj['locales'] = this.selected_locales.map(l => l.code)
      obj['audiences'] = this.new_audience_all_audiences
      obj['genders'] = this.genders
      obj['interests'] = this.selected_interests
      obj['location_types'] = this.location_type?.code
      obj['locations'] = this.locations
      if (this.activeDetailTargeting && this.included_interests_for_send.length && Object.keys(this.included_interests_for_send[0]).some(v => this.included_interests_for_send[0][v].length !== 0)) {
        obj['inclusion_targeting'] = this.included_interests_for_send
        if (Object.keys(this.excluded_interests_for_send).some(v => this.excluded_interests_for_send[v].length !== 0)) {
          obj['exclusion_targeting'] = this.excluded_interests_for_send
        }
      }
      return obj
    }
  },
  watch: {
    new_audience_data (v) {
      this.$emit('change-audience-data', v)
    },
    activeDetailTargeting () {
      this.$emit('change-audience-data', this.new_audience_data)
    }
  },
  methods: {
    ...mapActions('adsManager', [
      'load_custom_audiences',
      'load_location_types',
      'get_geo_locations',
      'get_interests',
      'get_reach_estimate',
      'create_audience',
      'load_campaign_targeting',
      'set_audience_property'
    ]),
    ...mapActions('notifications', ['addNotification', 'addExceptions']),
    changeRadius (event, type) {
      let location
      if (type === 'include') {
        location = this.include_geo_locations.find(v => {
          return v.key === event.key
        })
      }
      if (type === 'exclude') {
        location = this.exclude_geo_locations.find(v => {
          return v.key === event.key
        })
      }
      location.radius = event.value
      location.chosen_radius = event.value
    },
    changeIncludedInterests (data) {
      const init = this.inclusion_targeting
      this.inclusion_targeting = []
      this.inclusion_targeting = init
      this.inclusion_targeting = data
    },
    changeExcludedInterests (data) {
      this.exclusion_targeting = data
    },
    apply_watchers () {
      this.$watch('location_type', function (newValue) {
        this.get_reach_estimate_request()
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'location_types',
          value: newValue
        })
      })
      this.$watch('include_geo_locations', function (/* newValue */) {
        this.get_reach_estimate_request()
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'locations',
          value: this.locations
        })
      })
      this.$watch('exclude_geo_locations', function (/* newValue */) {
        this.get_reach_estimate_request()
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'locations',
          value: this.locations
        })
      })
      this.$watch('age_min', function (newValue) {
        if (newValue < 18) {
          this.age_min = 18
          newValue = 18
        }
        if (newValue > this.age_max) {
          this.age_min = this.age_max - 1
          newValue = this.age_max - 1
        }
        this.get_reach_estimate_request()
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'age_min',
          value: newValue
        })
      })
      this.$watch('age_max', function (newValue) {
        if (newValue > 65) {
          this.age_max = 65
          newValue = 65
        }
        if (newValue < this.age_min) {
          this.age_max = this.age_min + 1
          newValue = this.age_min + 1
        }
        this.get_reach_estimate_request()
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'age_max',
          value: newValue
        })
      })
      this.$watch('genders', function (newValue) {
        this.get_reach_estimate_request()
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'genders',
          value: newValue
        })
      })
      this.$watch('selected_interests', function (newValue) {
        this.get_reach_estimate_request()
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'interests',
          value: newValue
        })
      })
      this.$watch('included_audiences', function (/* newValue */) {
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'audiences',
          value: this.new_audience_all_audiences
        })
      })
      this.$watch('excluded_audiences', function (/* newValue */) {
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'audiences',
          value: this.new_audience_all_audiences
        })
      })
      this.$watch('selected_locales', function (newValue) {
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'locales',
          value: newValue.map(l => l.code)
        })
      })
      this.$watch('potential_reach_number', function (newValue) {
        this.set_audience_property({
          audienceId: this.audience.id,
          property: 'audience_size',
          value: newValue
        })
      })
    },
    handleAudienceCreate () {
      if (
        (this.include_geo_locations.length > 0 ||
        this.exclude_geo_locations.length > 0) ||
        (this.included_audiences.length > 0 ||
          this.excluded_audiences.length > 0)
      ) {
        if (this.activeDetailTargeting && this.included_interests_for_send.length === 0) {
          this.addNotification({
            body: 'No options for detailed targeting',
            type: 'danger'
          })
        } else {
          this.get_reach_estimate_request(() => {
            const id = this.audience?.id || Date.now()
            const data = { ...this.new_audience_data, id: id }
            this.$emit('save_audience', data)
          })
        }
      } else {
        this.addNotification({
          body: 'Targeting should include audience or location',
          type: 'danger'
        })
      }
    },
    toggleDetailTargeting (acc) {
      if (acc && this.inclusion_targeting.length === 0) {
        this.inclusion_targeting.push({
          id: this.inclusion_targeting.length,
          interests: [],
          life_events: [],
          income: [],
          behaviors: [],
          family_statuses: []
        })
        this.exclusion_targeting = {
          interests: [],
          life_events: [],
          income: [],
          behaviors: [],
          family_statuses: []
        }
      }
      this.activeDetailTargeting = acc
    },
    remove_audience () {
      this.$emit('remove_audience')
    },
    /* change_object (value) {
      this.selected_object = value
    }, */
    getStringNumber (num) {
      return formatters.getStringNumber(num)
    },
    set_location_setting (v) {
      this.locations_setting = v
    },
    search_geo_locations: _.throttle(async function ({ str, loading }) {
      if (str.length >= 3) {
        this.get_geo_locations({
          campaignId: this.getCampaign.id,
          query: str
        }).then(response => {
          this.geo_locations = response.data
          loading(false)
        })
      }
    }, 500),
    select_geo_location (value) {
      if (this.locations_setting === 'Include') {
        this.include_geo_locations.push(value)
      } else {
        this.exclude_geo_locations.push(value)
      }
    },
    select_audience (value) {
      if (this.audience_setting === 'Include') {
        this.included_audiences.push(
          this.getCustomAudiences
            ? this.getCustomAudiences.find(a => a?.fb_id === value.code)
            : null
        )
      } else {
        this.excluded_audiences.push(
          this.getCustomAudiences
            ? this.getCustomAudiences.find(a => a?.fb_id === value.code)
            : null
        )
      }
    },
    removeIncludedAudience (fbId) {
      this.included_audiences = this.included_audiences.filter(
        a => a.fb_id !== fbId
      )
    },
    removeExcludedAudience (fbId) {
      this.excluded_audiences = this.excluded_audiences.filter(
        a => a.fb_id !== fbId
      )
    },
    removeIncludedInterest (fbId) {
      this.included_interests = this.included_interests.filter(
        a => a.fb_id !== fbId
      )
    },
    removeExcludedInterest (fbId) {
      this.excluded_interests = this.excluded_interests.filter(
        a => a.fb_id !== fbId
      )
    },
    truncatedStr (str, length) {
      return str.length > length ? str.substr(0, length) + '...' : str
    },
    remove_included_geo_location (key) {
      this.include_geo_locations = this.include_geo_locations.filter(l => l.key !== key)
    },
    remove_excluded_geo_location (key) {
      this.exclude_geo_locations = this.exclude_geo_locations.filter(
        l => l.key !== key
      )
    },
    change_editable (key) {
      this.editable[key] = !this.editable[key]
    },
    search_interests: _.throttle(async function ({ str, loading }) {
      if (str.length >= 3) {
        this.loading.interests = true
        this.get_interests({
          campaignId: this.getCampaign.id,
          query: str
        }).then(response => {
          this.interests = response.data
          this.loading.interests = false
          loading(false)
        })
      }
    }, 500),
    /* select_interest (value) {
      if (this.interests_setting === 'Include') {
        this.included_interests.push(value)
      }
      if (this.interests_setting === 'Exclude') {
        this.excluded_interests.push(value)
      }
    }, */
    get_reach_estimate_request (callback = null) {
      // здесь нужен семафор, иначе может быть такое, что более ранний запрос завершится позднее, и тогда в переменной
      // potential_reach_number окажутся неактуальные данные
      if (
        (this.include_geo_locations && this.include_geo_locations.length > 0) ||
        (this.exclude_geo_locations && this.exclude_geo_locations.length > 0) ||
        this.included_audiences
      ) {
        this.get_reach_estimate({
          campaignId: this.getCampaign.id,
          payload: { targeting: this.new_audience_data }
        })
          .then(response => {
            this.potential_reach_number = response.data.users
            this.estimate_ready = response.data.estimate_ready
            if (callback) {
              callback(response)
            }
          }).catch(er => {
            this.addExceptions(er.response.data)
            throw er
          })
      }
    },
    set_genders (value) {
      this.genders = value
      this.change_editable('genders')
    }
  }
})
