import { render, staticRenderFns } from "./DetailedTargeting.vue?vue&type=template&id=49978ac6&scoped=true&"
import script from "./DetailedTargeting.vue?vue&type=script&lang=ts&"
export * from "./DetailedTargeting.vue?vue&type=script&lang=ts&"
import style0 from "./DetailedTargeting.vue?vue&type=style&index=0&id=49978ac6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49978ac6",
  null
  
)

export default component.exports