














import Vue from 'vue'
import { mixin as clickaway } from 'vue-clickaway'
export default Vue.extend({
  name: 'BaseDropdown',
  mixins: [clickaway],
  data () {
    return {
      showMenu: false
    }
  },
  provide () {
    return {
      showMenu: this.showMenu
    }
  },
  props: ['placeholder', 'position', 'onHover', 'styles'],
  computed: {
    selected () {
      return 'Select'
    }
  },
  methods: {
    away () {
      this.showMenu = false
    },
    showItems () {
      this.showMenu = !this.showMenu
    }
  }
})
