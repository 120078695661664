



































































































import Vue from 'vue'
import BaseLabelTag from '@/components/BaseLabelTag.vue'
import EditAudience from '@/components/ADSManager/NewCampaign/Audience/EditAudience.vue'
import formatters from '@/utils/formatters'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AudienceCard',
  components: {
    BaseLabelTag,
    EditAudience
  },
  props: {
    campaign: {
      required: true,
      type: Object
    },
    audience: {
      required: true,
      type: Object
    },
    editable: {
      required: true,
      type: Boolean
    },
    ind: {
      type: Number
    },
    is_adSet_edit: {
      type: Boolean,
      default: false
    },
    delete_available: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('adsManager', [
      'getCampaign',
      'getTargetingAudiencesData',
      'getTargetingIncludedLocations',
      'getTargetingExcludedLocations'
    ]),
    potential_reach_count () {
      return this.audience.audience_size >= 0 ? this.getStringNumber(this.audience.audience_size) : 'N/A'
    }
  },
  methods: {
    toggle_audience_editable () {
      this.$emit('toggle_audience_editable')
    },
    remove_audience () {
      this.$emit('remove_audience')
    },
    getStringNumber (num) {
      return formatters.getStringNumber(num)
    },
    save_audience (audience) {
      this.$emit('save_audience', audience)
    }
  }
})
